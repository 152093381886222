import axios from "axios";

export default {
  async getTecnici() {
    const response = await axios.get("rest/v1/search/users", {
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    });
    //console.log(response);
    return response.data.data;
  },
};
